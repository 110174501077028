import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_SENTRY_CLIENT_URL } from "$env/static/public";
import { env as pubEnv } from "$env/dynamic/public";
import { CaptureConsole } from "@sentry/integrations";
import { handleError as handleErrorDefault } from "$lib/utils/error-handler";

Sentry.init({
  dsn: PUBLIC_SENTRY_CLIENT_URL,
  tracesSampleRate: 0.001,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
  // enabled: process.env.NODE_ENV !== "development",
  environment: pubEnv.PUBLIC_DEPLOYMENT_ENV ?? "local",
  integrations: [
    new Sentry.Replay(),
    new CaptureConsole({
      levels: ["warn", "error"],
    }),
  ],

  beforeSend(event) {
    if (typeof window !== "undefined") {
      const userAgent = window.navigator.userAgent;
      if (!event.request) {
        event.request = {};
      }
      event.request.headers = {
        ...event.request.headers,
        "User-Agent": userAgent,
      };
    }
    return event;
  },
  enabled: process.env.NODE_ENV === "production",
});

export const handleError = ({ error, status, message }) => {
  const errorId = crypto.randomUUID();

  handleErrorDefault(
    error,
    {},
    {
      errorId,
      status,
      message,
    },
  );
};
